import React, { useState, useEffect } from "react";
import Hero from '../modules/Hero/Hero';
import Seo from "../components/seo";
import { graphql } from "gatsby";
import ChriateImage from '../components/ChriateImage';
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Header from '../modules/Header/Header';
import Footer from '../modules/Footer/Footer';
import WysiwygSection from '../modules/WysiwygSection/WysiwygSection';
import NewsArticleCard from '../components/NewsArticleCard/NewsArticleCard';

// TODO: Change url to live once L I V E.

let CMSURL = 'http:/localhost:8888/rare/mlc-cms';
process.env.NODE_ENV === 'production' ? CMSURL = 'https://cms.mlc.wa.edu.au/' : CMSURL = 'http://mlc.local';

export default function NewsPost({ data: { wp, wpMenu, wpStory, allwpStory } }) {
  const { databaseId, title, uri, newsArticle, date, seo, author } = wpStory;
  const { slug, categories } = wpStory;
  const menuItems = wpMenu?.menuItems ? wpMenu.menuItems : false;
  const { acfOptionsGeneralSettings: { themeSettings: { button1, button2 , largeBottomText, fieldGroupName, textUnderLogo, address, sitemap, trackers } } } = wp;
  const dateObj = new Date(date);
  const formattedDate = dateObj.getDate() + ' ' + dateObj.toLocaleString('default', { month: 'long' }) + ' ' + dateObj.getFullYear();
  const authorName = newsArticle?.author ? newsArticle.author : false;
  const [relatedArticles, setRelatedArticles] = useState([]);
  const ACFcategory = newsArticle?.category ? newsArticle.category : '';
  let category = categories?.nodes[0];
  if(!newsArticle?.category && categories?.nodes.length > 1) {
    category = categories.nodes.filter(cat => cat.uri.split('/').length === 4 && cat.name !== 'Uncategorised')[0];
  }


  useEffect(() => {
    fetch(`${CMSURL}/wp-json/wp/v2/stories?acf_format=standard&categories=${category?.termTaxonomyId}&cat_relation=AND&per_page=3&&exclude[0]=${databaseId}`)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      setRelatedArticles(data);
    }).catch((err) => {

    });
  },[]);


  //?  move the article content and bar to a module?
  return (
    <article>
    <Header data={{button1, button2}} slug={slug} />
    <Seo seo={seo} trackers={trackers} />
    <Breadcrumbs currentUrl={uri} currentTitle={title} ancestors={{nodes: [{title: 'Stories', uri: '/stories'}]}} categoryName={ACFcategory}/>
    <Hero heroType="small" heading={title} image={'/images/green-fullbleed-dark.jpg'} overlap={true} />
    <div className="article">
      <div className="container">
        {newsArticle?.heroImage && <div className="article__image">
           <ChriateImage image={newsArticle.heroImage} />
        </div>}
        <div className="article__block">

          <div className="article__socials mobile">
            <h4>Share</h4>
            <div className="article__bar-share-icons">
              <a className="facebook" href={`https://www.facebook.com/sharer/sharer.php?u=https://cms.mlc.wa.edu.au/${uri}`} target="_blank" rel="noreferrer">
              </a>

              {/* TODO: Instagram share link? */}
              {/* <a className="instagram" href={`https://www.instagram.com/?url=${uri}`} target="_blank" rel="noreferrer">
              </a> */}

              {/* TODO: YouTube share link? */}
              {/* <a className="youtube" href={`https://www.youtube.com/?url=${uri}`} target="_blank" rel="noreferrer">
              </a> */}

            </div>
          </div>
          {newsArticle?.contents && <WysiwygSection contents={newsArticle.contents}/>}

          <div className="article__bar">
            {ACFcategory &&<div className="article__bar-category">
              <h4>Category</h4>
              <p>{ACFcategory}</p>
            </div>}

            <div className="article__bar-date">
              <h4>Date</h4>
              <p>{formattedDate}</p>
            </div>


            {authorName && <div className="article__bar-author">
              <h4>Author</h4>
              <p>{authorName}</p>
            </div>}

            <div className="article__bar-share">
              <h4>Share</h4>
              <div className="article__bar-share-icons">
                <a className="facebook" href={`https://www.facebook.com/sharer/sharer.php?u=https://cms.mlc.wa.edu.au/${uri}`} target="_blank" rel="noreferrer">
                </a>

                {/* TODO: Instagram share link? */}
                {/* <a className="instagram" href={`https://www.instagram.com/?url=${uri}`} target="_blank" rel="noreferrer">
                </a> */}

                {/* TODO: YouTube share link? */}
                {/* <a className="youtube" href={`https://www.youtube.com/?url=${uri}`} target="_blank" rel="noreferrer">
                </a> */}

              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
    {relatedArticles?.length ?
      <>
      <section className="related-articles">
        <div className="container">
          <div className="related-articles__block">

            <h2>You may also like</h2>
            <div className="related-articles__grid">
              {relatedArticles.map((article, index) => {
                return <NewsArticleCard key={index} article={article} />
              })}
            </div>
          </div>
        </div>
      </section>
      </> : ''}
    <Footer data={{largeBottomText, fieldGroupName, textUnderLogo, address, sitemap}} menuItems={menuItems} />
    </article>
  )
}

export const query = graphql`
  query($id: String!) {
    wp {
      acfOptionsGeneralSettings {
        themeSettings {
          button1 {
            buttonType
            link {
              target
              title
              url
            }
          }
          button2 {
            buttonType
            link {
              target
              title
              url
            }
          }
          largeBottomText
          fieldGroupName
          textUnderLogo
          address
          sitemap {
            label
            links {
              link {
                target
                title
                url
              }
            }
            label2
            links2 {
              link {
                target
                title
                url
              }
            }
            label3
            links3 {
              link {
                target
                title
                url
              }
            }
          }
          trackers {
            script
            noscript
          }
        }
      }
    }
    wpMenu(locations: {eq: GATSBY_FOOTER_MENU}) {
      menuItems {
        nodes {
          label
          url
        }
      }
    }
    wpStory(id: { eq: $id }) {
      id
      databaseId
      title
      uri
      date
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }
      categories {
        nodes {
          termTaxonomyId
          slug
          uri
          name
        }
      }
      author {
        node {
          name
        }
      }
      newsArticle {
        author
        category
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 2.4)
            }
          }
        }
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        contents {
          contentType
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          text
          videoId
        }
      }
    }
    allWpStory {
      edges {
        node {
          title
          newsArticle {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`